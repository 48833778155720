@import './variables';
$--font-name: 'Inter-Regular';
$--font-name-bold: 'Inter-SemiBold';

@mixin font-family-regular {
  font-family: $--font-name;
}

@mixin font-family-medium {
  font-family: $--font-name;
}

@mixin font-family-bold {
  font-family: $--font-name-bold;
}

@mixin text-small($font-size: $--font-size-small) {
  @include font-family-regular;
  font-size: $font-size;
}

@mixin text-base($font-size: $--font-size-base) {
  @include font-family-regular;
  font-size: $font-size;
}

@mixin text-medium($font-size: $--font-size-medium) {
  @include font-family-medium();
  font-size: $font-size;
}

@mixin text-bold($font-size: $--font-size-large) {
  @include font-family-bold();
  font-size: $font-size;
}

@mixin text-base-italic($font-size: $--font-size-base) {
  @include text-base($font-size);
  font-style: italic;
}

.ql-font-arial {
  font-family: Arial, sans-serif;
}

.ql-font-verdana {
  font-family: Verdana, sans-serif;
}

.ql-font-tahoma {
  font-family: Tahoma, sans-serif;
}

.ql-font-times-new-roman {
  font-family: 'Times New Roman', serif;
}

.ql-font-georgia {
  font-family: Georgia, serif;
}

.ql-font-courier-new {
  font-family: 'Courier New', monospace;
}

.ql-font-comic-sans-ms {
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.ql-font-trebuchet-ms {
  font-family: 'Trebuchet MS', sans-serif;
}

.ql-font-impact {
  font-family: Impact, sans-serif;
}

.ql-font-helvetica {
  font-family: Helvetica, sans-serif;
}

.ql-picker.ql-font span[data-value='arial']::before {
  font-family: 'Arial', sans-serif;
  content: 'Arial' !important;
}

.ql-picker.ql-font span[data-value='verdana']::before {
  font-family: 'Verdana', sans-serif;
  content: 'Verdana' !important;
}

.ql-picker.ql-font span[data-value='tahoma']::before {
  font-family: 'Tahoma', sans-serif;
  content: 'Tahoma' !important;
}

.ql-picker.ql-font span[data-value='times-new-roman']::before {
  font-family: 'Times New Roman', serif;
  content: 'Times New Roman' !important;
}

.ql-picker.ql-font span[data-value='georgia']::before {
  font-family: 'Georgia', serif;
  content: 'Georgia' !important;
}

.ql-picker.ql-font span[data-value='courier-new']::before {
  font-family: 'Courier New', monospace;
  content: 'Courier New' !important;
}

.ql-picker.ql-font span[data-value='comic-sans-ms']::before {
  font-family: 'Comic Sans MS', cursive, sans-serif;
  content: 'Comic Sans MS' !important;
}

.ql-picker.ql-font span[data-value='trebuchet-ms']::before {
  font-family: 'Trebuchet MS', sans-serif;
  content: 'Trebuchet MS' !important;
}

.ql-picker.ql-font span[data-value='impact']::before {
  font-family: 'Impact', sans-serif;
  content: 'Impact' !important;
}

.ql-picker.ql-font span[data-value='helvetica']::before {
  font-family: 'Helvetica', sans-serif;
  content: 'Helvetica' !important;
}
